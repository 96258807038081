// app/javascript/channels/toastr_channel.js
import consumer from "./consumer"

document.addEventListener("DOMContentLoaded", () => {
  let job_id = $("#job_id").val(); // Assuming you have a hidden field with job_id
  consumer.subscriptions.create({ channel: "ToastrChannel", job_id: job_id }, {
    connected() {
      console.log("Connected to ToastrChannel");
    },
    disconnected() {
      console.log("Disconnected from ToastrChannel");
    },
    received(data) {
      let msg = data.message;
      if (data.type === "error") {
        if (data.job_type && data.job_type === "auto_save") {
          localStorage.setItem('graphicCablesArray', JSON.stringify([]))
          console.log(msg);
        } else {
          localStorage.setItem('graphicCablesArray', JSON.stringify([]))
          toastr.error(msg || 'Something went wrong');
        }
        saveButtonEnable();
      } else if (data.type === "success") {
        if (data.job_type && data.job_type === "auto_save") {
          localStorage.setItem('graphicCablesArray', JSON.stringify([]))
          console.log("Auto save successful");
          saveButtonDisable();
        } else {
          if (data.graphic_cables && data.graphic_cables.length > 0) {
            localStorage.setItem('graphicCablesArray', JSON.stringify(data.graphic_cables))
            saveButtonDisable();
          } else {
            localStorage.setItem('graphicCablesArray', JSON.stringify([]))
            saveButtonDisable();
          }
          toastr.success(msg || 'Your last action successfully completed');
        }
      } else {
        toastr.info(msg);
        // Handle other types of messages if needed
      }
      if (data.job_type && data.job_type === "modify_svg") {
        clickUndoSvg();
      }

      if ($('#rightFburger')) {
        $('#rightFburger').removeClass("is-hidden");
      }
    }
  });
});

function saveButtonEnable() {
  var saveBtn = window.document.getElementById("save-btn-color")
  if (saveBtn) {
    saveBtn.classList.remove('check-btn-color-grey');
  }
}

function saveButtonDisable() {
  var saveBtn = window.document.getElementById("save-btn-color")
  if (saveBtn) {
    saveBtn.classList.add('check-btn-color-grey');
  }
}

function clickUndoSvg() {
  var undoSvg = window.document.getElementById("undoSvg");
  // Check if the anchor tag exists
  if (undoSvg) {
    // Programmatically trigger a click event on the anchor tag
    undoSvg.click();
  } else {
    console.error("Anchor tag with ID 'undoSvg' not found.");
  }
}